import React, { useState } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import Link from '../Link/Link'
import { useRouter } from 'next/router'
import {
  Container,
  createStyles,
  Drawer,
  Fade,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Button from '../Button/Button'
import { Cart, LayoutStyle, LogoAttr } from '../../typings/base'
import RoomIcon from '@material-ui/icons/Room'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText from '../Global/CMSText'
import ChangeStoreModal from '../InStore/ChangeStoreModal'
import Menu from './Menu'
import { logoPosition, menuPosition } from '../../utils/utils'
import Basket from '../Basket/Basket'
import useDrawerStyles from '../Layout/Drawer.style'
import ChangeLanguageModal from '../Global/ChangeLanguageModal'
import { ReactSVG } from 'react-svg'
import Banner from '../Banner/Banner'
import { Close } from '@material-ui/icons'
import InputButton from '../Button/InputButton'
import DialogFullscreen from '../Dialog/DialogFullscreen'
import SearchInput from '../Input/SearchInput'
import * as gtag from '../../utils/gtag'

const useStyles = makeStyles<Theme, LayoutStyle>((theme: Theme) =>
  createStyles({
    header: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.appBar,
      color: theme.extraColors.greyText,
      boxShadow: theme.shadows[1],
      background: (LayoutStyle) =>
        !LayoutStyle.isTransparent && LayoutStyle.backgroundColor
          ? LayoutStyle.backgroundColor
          : theme.palette.common.white,
      transition: theme.transitions.create(['transform', 'top']),
      transform: `translateY(0)`,
    },
    headerBanner: {
      top: (LayoutStyle) =>
        !LayoutStyle.isHomePage && LayoutStyle.isBanner ? 0 : 40,
      [theme.breakpoints.down('md')]: {
        top: (LayoutStyle) =>
          !LayoutStyle.isHomePage && LayoutStyle.isBanner ? 0 : 60,
      },
    },
    headerTop: {
      transform: 'none',
    },
    headerScrolling: {
      transition: theme.transitions.create('transform'),
      transform: `translateY(-${theme.sizes.headerHeight * 3}px)`,
      '&.header--top': {
        transform: 'none',
      },
    },
    headerTransparent: {
      color: (LayoutStyle) =>
        LayoutStyle.isTransparent && theme.palette.common.white,
      transition: theme.transitions.create('background'),
      background: (LayoutStyle) => LayoutStyle.isTransparent && 'transparent',
      boxShadow: 'none',
    },
    headerScrollingTransparent: {
      boxShadow: theme.shadows[1],
      background: theme.palette.common.white,
    },
    headerLayout: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: theme.sizes.headerHeight,
      transition: theme.transitions.create('height'),
    },
    headerLayoutTransparent: {
      [theme.breakpoints.up('md')]: {
        height: theme.sizes.headerHeightTransparent,
      },
    },
    headerCol: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    headerLeft: {
      display: 'flex',
      gap: theme.spacing(2),
      '& a': {
        display: 'block',
      },
      [theme.breakpoints.up('md')]: {},
    },
    headerCenter: {
      justifyContent: 'center',
    },
    header3Col: {
      width: `${(1 / 3) * 100}%`,
    },
    headerRight: {
      justifyContent: 'flex-end',
    },
    headerLink: {
      display: 'block',
      '&>img': {
        display: 'block',
      },
    },
    headerTitle: {
      marginLeft: theme.spacing(2),
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: '0.085em',
      color: theme.palette.common.black,
    },
    headerIcons: {
      display: 'flex',
      flexWrap: 'nowrap',
      [theme.breakpoints.up('md')]: {
        borderLeft: `1px solid currentColor`,
        marginLeft: theme.spacing(2),
      },
    },
    headerIconsAlt: {
      [theme.breakpoints.up('md')]: {
        borderLeft: 0,
      },
    },
    headerBtn: {
      minWidth: 20,
      padding: 0,
      fontSize: 12,
      color: (LayoutStyle) =>
        !LayoutStyle.isTransparent &&
        (LayoutStyle.color ? LayoutStyle.color : theme.palette.common.black),
      marginLeft: theme.spacing(2),
      background: 'none',
      '& .MuiSvgIcon-root': {
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(0.5),
        },
      },
      '&:hover, &:focus': {
        color: (LayoutStyle) =>
          !LayoutStyle.isTransparent &&
          (LayoutStyle.backgroundColor &&
          LayoutStyle.color &&
          LayoutStyle.hover.color
            ? LayoutStyle.hover.color
            : theme.palette.primary.main),
        background: 'none',
      },
      '&[aria-current=true]': {
        color: theme.palette.primary.main,
      },
      '& p': {
        margin: 0,
      },
    },
    headerBtnTransparent: {
      color: theme.palette.common.white,
    },
    logo: {
      height: theme.sizes.headerHeight - theme.spacing(2),
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '40vw',
        objectFit: 'contain',
      },
    },
    logoTransparent: {
      [theme.breakpoints.up('md')]: {
        height: theme.sizes.headerHeightTransparent - theme.spacing(4),
      },
    },
    colorPrimary: {
      color: theme.palette.primary.main,
    },
    menuItem: {
      color: theme.palette.common.black,
    },
    menuItemTransparent: {
      color: theme.palette.common.white,
    },
    languageSelector: {
      marginLeft: '.5rem',
      '& a': {
        color: (LayoutStyle) =>
          !LayoutStyle.isTransparent &&
          (LayoutStyle.backgroundColor && LayoutStyle.color
            ? LayoutStyle.color
            : theme.palette.common.black),
        '&:hover, &:focus': {
          color: (LayoutStyle) =>
            !LayoutStyle.isTransparent &&
            LayoutStyle.backgroundColor &&
            LayoutStyle.color &&
            LayoutStyle.hover.color &&
            LayoutStyle.hover.color,
        },
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    languageSeparator: {
      lineHeight: '1px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '3px',
      },
    },
    langbtn: {
      minWidth: 'inherit',
      padding: 0,
    },
    activeLang: {
      '&[aria-current=true]': {
        color: (LayoutStyle) =>
          !LayoutStyle.isTransparent &&
          (LayoutStyle.backgroundColor && LayoutStyle.hover.color
            ? LayoutStyle.hover.color
            : theme.palette.common.black),
        pointerEvents: 'none',
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
    },
    inlineDiv: {
      display: 'inline-flex',
      justifyContent: 'start',
      alignItems: 'center',
      width: '100%',
    },
    inStoreLines: {
      lineHeight: 1,
    },
    openModalStoreLink: {
      textTransform: 'none',
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
      fontSize: 12,
    },
    drawerContent: {
      background: theme.extraColors.grey,
    },
    drawerFooter: {
      background: theme.extraColors.greyLight,
      padding: theme.spacing(1),
    },
    headerBurger: {
      background: 'none',
      boxShadow: 'none',
      color: (LayoutStyle) =>
        !LayoutStyle.isTransparent &&
        (LayoutStyle.backgroundColor && LayoutStyle.color
          ? LayoutStyle.color
          : theme.palette.common.black),
    },
    drawerFooterBtn: {
      width: '100%',
      borderRadius: 0,
      textAlign: 'left',
      justifyContent: 'flex-start',
      background: theme.palette.common.white,
      color: theme.extraColors.greyText,
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(2),
      },
    },
    btnIcon: {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
    btnIconSvg: {
      lineHeight: 1,
      '& .injected-svg': {
        display: 'inline-block',
        width: '1em',
        height: '1em',
        fontSize: '1.5rem',
        fill: 'currentColor',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
      },
    },
  })
)

type TProps = {
  isHomePage?: boolean
  logo?: LogoAttr
  doc: any
  t?: any
  deleteUser: any
  logoTransparent?: LogoAttr
  isTransparent?: boolean
  title?: string
  showOnScrollUp?: boolean
  showOnTop?: boolean
  parameters: any
  isBanner?: boolean
  inStoreText: any
  stores: any
  background?: string
  textColor?: string
  hoverColor?: string
  cart?: Cart
  basket: any
  account?: any
  faq?: any
  funnel?: any
  takeback?: any
  submenuBackgroundColor?: string
  submenuTextColor?: string
  isDisplayedBannerOnAllPages?: boolean
  banner?: any
  pageName?: string
}

const mapStateToProps = (state) => ({
  stores: state.stores,
  cart: state.cart,
  parameters: state.parameters,
})

const Header = ({
  isHomePage = false,
  logo,
  doc,
  t,
  stores,
  parameters,
  logoTransparent,
  isTransparent,
  showOnScrollUp,
  showOnTop,
  inStoreText,
  isBanner,
  background,
  textColor,
  hoverColor,
  cart,
  basket,
  account,
  faq,
  funnel,
  takeback,
  submenuBackgroundColor,
  submenuTextColor,
  isDisplayedBannerOnAllPages,
  banner,
  pageName,
}: TProps) => {
  const router = useRouter()
  const backgroundHeaderColor = background
  const textMenuColor = textColor
  const hoverMenuColor = hoverColor
  const LayoutStyle = {
    backgroundColor: backgroundHeaderColor,
    color: textMenuColor,
    hover: {
      color: hoverMenuColor,
    },
    isTransparent: isTransparent && showOnTop,
    submenuBackgroundColor,
    submenuTextColor,
    isHomePage,
    isDisplayedBannerOnAllPages,
    isBanner,
  }
  const classes = useStyles(LayoutStyle)
  const drawerClasses = useDrawerStyles()
  const theme = useTheme()
  const { trackEvent } = useMatomo()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const [openModalInStore, setOpenModalInStore] = useState(false)
  const isIconOnly = doc?.data?.menu_icon_only
  const isSticky = doc?.data?.header_sticky
  const menuPos = menuPosition(doc?.data?.main_menu)
  const burgerPos = menuPosition(doc?.data?.burger_menu)
  const logoPos = logoPosition(doc?.data?.logo_position)
  const accountIconPrismic = account?.data?.accountbutton
  const faqIconPrismic = faq?.data?.faqbutton
  const { locale, locales } = router
  const [openMenu, setOpenMenu] = useState(false)
  const [primaryMenuActive, setPrimaryMenuActive] = useState(false)
  const [openLanguageModal, setOpenLanguageModal] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(router.locale)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const isAccountIconDefined =
    accountIconPrismic?.url &&
    accountIconPrismic?.url?.length > 0 &&
    accountIconPrismic?.url.split('.').pop() === 'svg'
  const isFaqIconDefined =
    faqIconPrismic?.url &&
    faqIconPrismic?.url?.length > 0 &&
    faqIconPrismic?.url.split('.').pop() === 'svg'

  const handlePrimaryMenuActive = () => {
    if (primaryMenuActive) {
      setPrimaryMenuActive(false)
    } else {
      setPrimaryMenuActive(true)
    }
  }

  const openLanguageModalAndSelectedLanguage = (lang) => {
    // if we have no item in cart we do not open the modal and just redirect, else we open the modal
    if (cart?.item?.items?.length > 0) {
      setOpenLanguageModal(true)
    } else {
      router.push(
        { pathname: '/', query: {} },
        { pathname: '/' },
        { locale: lang }
      )
    }
    setSelectedLanguage(lang)
  }

  const HeaderLangs = ({
    isMenuItemTransparent,
    spacing = true,
  }: {
    isMenuItemTransparent: boolean
    spacing?: boolean
  }) =>
    locales?.length > 1 && (
      <span
        className={clsx('header__lang', {
          [classes.languageSelector]: spacing,
        })}
      >
        {locales?.map((lang, index) => {
          return (
            <React.Fragment key={lang}>
              <span>
                <span>
                  <Button
                    size="small"
                    aria-current={lang === locale}
                    className={clsx('header__langbtn', [
                      classes.langbtn,
                      classes.menuItem,
                      isMenuItemTransparent && classes.menuItemTransparent,
                      lang === locale && classes.activeLang,
                    ])}
                    onClick={() => openLanguageModalAndSelectedLanguage(lang)}
                  >
                    {lang.toUpperCase().substring(0, 2)}
                  </Button>
                </span>
                <ChangeLanguageModal
                  open={openLanguageModal}
                  doc={doc}
                  t={t}
                  handleClose={() => setOpenLanguageModal(false)}
                  lang={selectedLanguage}
                  pathname={router.pathname}
                  query={router.query}
                />
              </span>
              <span className={classes.languageSeparator}>
                {locales.length === index + 1 ? (
                  <>&nbsp;</>
                ) : (
                  <>&nbsp;-&nbsp;</>
                )}
              </span>
            </React.Fragment>
          )
        })}
      </span>
    )

  const Logo = () => (
    <img
      src={logo ? logo?.url : '/images/logo_default_grey.svg'}
      className={clsx('header__logo', classes.logo)}
      alt={logo?.alt !== null ? logo?.alt : ''}
    />
  )

  const accountIcon = isAccountIconDefined ? (
    <ReactSVG
      src={accountIconPrismic.url}
      className={clsx(
        'account__icon',
        'account__icon--svg',
        classes.btnIcon,
        classes.btnIconSvg
      )}
      wrapper="span"
    />
  ) : (
    <AccountCircleIcon />
  )

  const faqIcon = isFaqIconDefined ? (
    <ReactSVG
      src={faqIconPrismic.url}
      className={clsx(
        'faq__icon',
        'faq__icon--svg',
        classes.btnIcon,
        classes.btnIconSvg
      )}
      wrapper="span"
    />
  ) : (
    <HelpOutlineIcon />
  )

  const LogoTransparent = () => (
    <Link href="/">
      <a
        className={classes.headerLink}
        onClick={() =>
          trackEvent({
            category: 'Header',
            href: '/account',
            action: 'Header to Homepage',
            name: `${router.pathname}`,
          })
        }
      >
        {isHomePage && logoTransparent && isTransparent && showOnTop ? (
          <h1>
            <img
              src={
                logoTransparent?.url
                  ? logoTransparent?.url
                  : '/images/logo_default_white.svg'
              }
              className={clsx('header__logo', [
                classes.logo,
                isTransparent && showOnTop && classes.logoTransparent,
              ])}
              alt={logoTransparent?.alt ?? ''}
            />
          </h1>
        ) : (
          <Logo />
        )}
      </a>
    </Link>
  )

  const HeaderAccountLink = () => (
    <Link
      href={doc?.data?.account_link_to_register ? '/register' : '/account'}
      passHref
    >
      <Button
        disableRipple
        size="small"
        onClick={() =>
          trackEvent({
            category: 'Header',
            href: '/account',
            action: 'Header to My account',
            name: `${router.pathname}`,
          })
        }
        data-testid={'account_header'}
        component="a"
        className={clsx('header__cta', 'header__cta--account', [
          classes.headerBtn,
          isTransparent && showOnTop && classes.headerBtnTransparent,
          router.pathname.includes('account') && classes.colorPrimary,
        ])}
      >
        {accountIcon}
        {isDesktop && isIconOnly && (
          <Typography
            variant="srOnly"
            color={
              router.pathname.includes('account') ? 'primary' : 'textPrimary'
            }
          >
            <CMSText
              data={inStoreText?.data?.header_change_store}
              defaultText={t('texts:instore:header_change_store')}
            />
          </Typography>
        )}
        {isDesktop && !isIconOnly && (
          <CMSText
            data={doc?.data?.my_account}
            defaultText={t('texts:general:my_account')}
          />
        )}
        {!isDesktop && (
          <Typography
            variant="srOnly"
            color={
              router.pathname.includes('account') ? 'primary' : 'textPrimary'
            }
          >
            <CMSText
              data={inStoreText?.data?.header_change_store}
              defaultText={t('texts:instore:header_change_store')}
            />
          </Typography>
        )}
      </Button>
    </Link>
  )

  const HeaderFaqLink = () => (
    <Link href={doc?.data?.custom_faq_link?.url || '/faq'} passHref>
      <Button
        target={doc?.data?.custom_faq_link?.target}
        disableRipple
        size="small"
        onClick={() =>
          trackEvent({
            category: 'Header',
            href: doc?.data?.custom_faq_link?.url || '/faq',
            action: 'Header to FAQ',
            name: `${router.pathname}`,
          })
        }
        component="a"
        aria-current={router.pathname === '/faq'}
        disabled={router.pathname === '/faq'}
        className={clsx('header__cta', 'header__cta--faq', [
          classes.headerBtn,
          isTransparent && showOnTop && classes.headerBtnTransparent,
        ])}
      >
        {faqIcon}
        {isDesktop && isIconOnly && (
          <Typography variant="srOnly">
            <CMSText
              data={doc?.data?.faq}
              defaultText={t('texts:general:FAQ')}
            />
          </Typography>
        )}
        {isDesktop && !isIconOnly && (
          <CMSText data={doc?.data?.faq} defaultText={t('texts:general:FAQ')} />
        )}
        {!isDesktop && (
          <Typography variant="srOnly">
            <CMSText
              data={doc?.data?.faq}
              defaultText={t('texts:general:FAQ')}
            />
          </Typography>
        )}
      </Button>
    </Link>
  )

  const handleSearchButton = () => {
    setOpen(true)
    trackEvent({
      category: 'SearchButton',
      action: `Open Seach bar`,
    })
    gtag.tracker('searchBar', { value: open })
  }
  return (
    <header
      className={clsx('header', [
        classes.header,
        showOnTop && [
          'header--top',
          classes.headerTop,
          `header--top--page__${pageName}`,
        ],
        isBanner && showOnTop && classes.headerBanner,
        isTransparent &&
          showOnTop && ['header--transparent', classes.headerTransparent],
        !isSticky && showOnScrollUp && classes.headerScrolling,
        `header--page__${pageName}`,
      ])}
    >
      {isBanner && isDisplayedBannerOnAllPages && !isHomePage && (
        <Banner banner={banner} t={t} />
      )}
      <Container maxWidth="xl">
        <div
          className={clsx('header__layout', [
            classes.headerLayout,
            isTransparent && showOnTop && classes.headerLayoutTransparent,
          ])}
        >
          <div
            className={clsx(
              'header__col',
              'header__left',
              classes.headerCol,
              classes.headerLeft,
              menuPos === 'Center' && classes.header3Col,
              logoPos === 'Center' && !isDesktop && classes.header3Col
            )}
          >
            {!isDesktop && burgerPos === 'Left' && (
              <MenuIcon
                className={clsx('header__burger', classes.headerBurger)}
                onClick={() => setOpenMenu(true)}
              />
            )}
            {isDesktop ? (
              <LogoTransparent />
            ) : (
              logoPos === 'Left' && <LogoTransparent />
            )}
            {isDesktop && menuPos === 'Left' && (
              <Menu
                general={doc}
                t={t}
                type={'primary'}
                LayoutStyle={LayoutStyle}
              />
            )}
          </div>
          {isDesktop && menuPos === 'Center' && (
            <div
              className={clsx(
                'header__col',
                'header__center',
                classes.headerCol,
                classes.headerCenter,
                classes.header3Col
              )}
            >
              <Menu
                general={doc}
                t={t}
                type={'primary'}
                LayoutStyle={LayoutStyle}
              />
            </div>
          )}
          {!isDesktop && logoPos === 'Center' && (
            <div
              className={clsx(
                'header__col',
                'header__center',
                classes.headerCol,
                classes.headerCenter,
                classes.header3Col
              )}
            >
              <LogoTransparent />
            </div>
          )}
          <div
            className={clsx(
              'header__col',
              'header__right',
              classes.headerCol,
              classes.headerRight,
              menuPos === 'Center' && classes.header3Col,
              !isDesktop && logoPos === 'Center' && classes.header3Col
            )}
          >
            {isDesktop && menuPos === 'Right' && (
              <Menu
                general={doc}
                t={t}
                type={'primary'}
                LayoutStyle={LayoutStyle}
              />
            )}
            {isDesktop && (
              <Menu
                general={doc}
                t={t}
                LayoutStyle={LayoutStyle}
                type={'secondary'}
              />
            )}
            {doc?.data?.enable_search && (
              <InputButton
                iconOnly={doc?.data?.search_type}
                label={doc?.data?.search_label}
                iconUrl={doc?.data?.search_icon?.url}
                transparentBackground={LayoutStyle.isTransparent}
                onClick={handleSearchButton}
                isMobile={!isDesktop}
                LayoutStyle={LayoutStyle}
              />
            )}
            {!isDesktop && doc?.data?.show_header_langs_on_mobile && (
              <HeaderLangs isMenuItemTransparent={isTransparent && showOnTop} />
            )}
            {(burgerPos === 'No menu' ||
              (parameters?.items?.in_store &&
                stores?.selectedStore &&
                router.pathname !== '/')) && (
              <div
                className={clsx(
                  'header__icons',
                  classes.headerIcons,
                  menuPos === 'No menu' && classes.headerIconsAlt
                )}
              >
                {parameters?.items?.in_store &&
                  stores?.selectedStore &&
                  router.pathname !== '/' && (
                    <Button
                      disableRipple
                      size="small"
                      onClick={() => setOpenModalInStore(true)}
                      data-testid={'instore_header'}
                      className={clsx('header__cta', 'header__cta--instore', [
                        classes.headerBtn,
                        isTransparent &&
                          showOnTop &&
                          classes.headerBtnTransparent,
                        router.pathname.includes('account') &&
                          classes.colorPrimary,
                      ])}
                    >
                      <div className={classes.inlineDiv}>
                        <RoomIcon />
                        {isDesktop && (
                          <div className={classes.inStoreLines}>
                            <div>{stores?.selectedStore?.name}</div>
                            <span
                              onClick={() => setOpenModalInStore(true)}
                              className={clsx(
                                classes.openModalStoreLink,
                                'header__cta--instore--choice'
                              )}
                            >
                              <CMSText
                                data={doc?.data?.modale_title}
                                defaultText={t('texts:instore:modale_title')}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    </Button>
                  )}
                {burgerPos === 'No menu' ? (
                  <>
                    <HeaderAccountLink />
                    <HeaderFaqLink />
                  </>
                ) : (
                  isDesktop && (
                    <>
                      <HeaderAccountLink />
                      <HeaderFaqLink />
                    </>
                  )
                )}
              </div>
            )}
            {isDesktop && (
              <HeaderLangs
                isMenuItemTransparent={isTransparent && showOnTop && isDesktop}
              />
            )}
            <Basket
              settings={doc?.data}
              basket={basket}
              isAbsolute
              textShippings={funnel?.data}
              nextStep="/information"
              hidePrice={doc?.data?.only_add_with_pack}
              showSizes={doc?.data?.show_size_name}
              layoutStyle={LayoutStyle}
              takeback={takeback}
            />
            {!isDesktop && burgerPos === 'Right' && (
              <MenuIcon
                className={clsx('header__burger', classes.headerBurger)}
                onClick={() => setOpenMenu(true)}
              />
            )}
          </div>
        </div>
      </Container>
      <ChangeStoreModal
        open={openModalInStore}
        doc={inStoreText}
        t={t}
        handleClose={() => setOpenModalInStore(false)}
      />
      {!isDesktop && (
        <Drawer
          anchor={burgerPos === 'Right' ? 'right' : 'left'}
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          className={clsx('menu-mobile', drawerClasses.drawer)}
        >
          <div
            className={clsx('menu-mobile__layout', drawerClasses.drawerLayout)}
          >
            <div
              className={clsx(
                'menu-mobile__header',
                drawerClasses.drawerHeader
              )}
            >
              <Logo />
              <Button
                size={'small'}
                color={'default'}
                disableRipple
                endIcon={<Close />}
                onClick={() => setOpenMenu(false)}
                className={clsx(
                  'menu-mobile__close',
                  drawerClasses.drawerClose
                )}
                disableTouchRipple
                disableElevation
              >
                <CMSText
                  asText
                  data={doc?.data?.menu_close_text}
                  defaultText={t('texts:general:menu:close')}
                />
              </Button>
            </div>
            <div
              className={clsx(
                'menu-mobile__content',
                drawerClasses.drawerContent,
                classes.drawerContent
              )}
            >
              <Fade in={openMenu} timeout={600}>
                <div>
                  <div onClick={() => handlePrimaryMenuActive()}>
                    <Menu
                      general={doc}
                      t={t}
                      type={'primary'}
                      LayoutStyle={LayoutStyle}
                      isMobile
                    />
                  </div>
                  {!primaryMenuActive && (
                    <Menu
                      general={doc}
                      t={t}
                      type={'secondary'}
                      LayoutStyle={LayoutStyle}
                      isMobile
                    />
                  )}
                </div>
              </Fade>
            </div>
            <div
              className={clsx(
                'menu-mobile__footer',
                drawerClasses.drawerFooter,
                classes.drawerFooter
              )}
            >
              <Link href={doc?.data?.custom_faq_link?.url || '/faq'} passHref>
                <Button
                  target={doc?.data?.custom_faq_link?.target}
                  disableRipple
                  size="small"
                  onClick={() =>
                    trackEvent({
                      category: 'Header',
                      href: doc?.data?.custom_faq_link?.url || '/faq',
                      action: 'Header to FAQ',
                      name: `${router.pathname}`,
                    })
                  }
                  component="a"
                  aria-current={router.pathname === '/faq'}
                  disabled={router.pathname === '/faq'}
                  className={clsx('menu-mobile__cta', 'menu-mobile__cta--faq', [
                    classes.drawerFooterBtn,
                  ])}
                >
                  <HelpOutlineIcon />
                  <CMSText
                    asText
                    data={doc?.data?.faq}
                    defaultText={t('texts:general:FAQ')}
                  />
                </Button>
              </Link>
              <Link
                href={
                  doc?.data?.account_link_to_register ? '/register' : '/account'
                }
                passHref
              >
                <Button
                  disableRipple
                  size="small"
                  onClick={() =>
                    trackEvent({
                      category: 'Header',
                      href: '/account',
                      action: 'Header to My account',
                      name: `${router.pathname}`,
                    })
                  }
                  data-testid={'account_header'}
                  component="a"
                  className={clsx(
                    'menu-mobile__cta',
                    'menu-mobile__cta--account',
                    [classes.drawerFooterBtn]
                  )}
                >
                  <AccountCircleIcon />
                  <CMSText
                    data={doc?.data?.my_account}
                    defaultText={t('texts:general:my_account')}
                  />
                </Button>
              </Link>
              {!isDesktop && (
                <HeaderLangs isMenuItemTransparent={false} spacing={false} />
              )}
            </div>
          </div>
        </Drawer>
      )}
      <DialogFullscreen open={open} setOpen={setOpen}>
        <SearchInput
          search={search}
          setSearch={setSearch}
          label={doc?.data?.search_label}
          placeholder={doc?.data?.search_placeholder}
          searchIconUrl={doc?.data?.search_icon?.url}
          action={() => {
            if (search)
              router.push(`/search?q=${search}`).then(() => setOpen(false))
          }}
        />
      </DialogFullscreen>
    </header>
  )
}

export default connect(mapStateToProps)(Header)
